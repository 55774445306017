import BaseApi from './BaseApi';

const Anamnesis = () => {

    const _api = BaseApi.api;

    const getAll = () => {
        return _api.get('/api/patients');
    };

    const getByDoctor = (doctorId) => {
        return _api.get(`/api/doctors/${doctorId}/patients/`);
    };

    const getOne = (id) => {
        return _api.get('/api/patients/'+id);
    };

    const create = (data) => {
        return _api.post('/api/patients', data);
    };

    const update = (data, id) => {
        return _api.put('/api/patients/'+id, data);
    };

    const remove = (id) => {
        return _api.delete('/api/patients/', id);
    };

    return {
        getAll,
        getByDoctor,
        getOne,
        create,
        update,
        remove
    }

};

export default Anamnesis();
