import React, {Component} from 'react';
import { 
  MDBRow,
  MDBCol,
  MDBCard,
  MDBView,
  MDBCardBody,
  MDBInput,
  MDBContainer,
  MDBBtn,
} from 'mdbreact';
import { withTranslation } from 'react-i18next';
import {Redirect} from 'react-router';
import '../assets/css/v2.css';
import DoctorApi from "../api/DoctorApi";
import {loadState} from "../storage/storage";
import BaseApi from "../api/BaseApi";
class Profile extends Component{
    constructor(props) {
        super(props);
        this.state = {
            date: new Date().toDateString(),
            name: "",
            oldPassword: "",
            currentPassword: "",
            password: "",
            redirect: false
        }
    }

    submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
            let doctor = {
                name: this.state.name,
                password: this.state.password,
                role: "ADMIN"
            }
            DoctorApi.update(doctor, loadState('id')).then((res) => {
                if (res.data) {
                    console.log("created doctor", res.data);
                    this.setState({redirect: true})
                }
            }, err => {
                console.log(err);
            })
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    componentDidMount() {
        const token1 = loadState('token');
        BaseApi.authToken=token1;
        console.log(loadState('id'));
        DoctorApi.getOne(loadState('id')).then(res => {
            if (res.data) {
                console.log(res.data);
                this.setState({
                    name: res.data.name,
                    oldPassword: res.data.password
                })
            }
        })
    }
    render() {
        const {t}=this.props;
        if(this.state.redirect){
            return(
                <Redirect to={"/"} />
            )
        }
        return (
            <div id="profile-v2" className="mb-5">
                <MDBContainer fluid>
                    <MDBRow>
                        <MDBCol lg="8" className="mb-r">
                            <MDBCard narrow>
                                <MDBView cascade className="mdb-color lighten-3 card-header">
                                    <h5 className="mb-0 font-weight-bold text-center text-white">Edit Account Details</h5>
                                </MDBView>
                                <MDBCardBody className="text-center">
                                    <form onSubmit={this.submitHandler}>
                                    <MDBRow>
                                        <MDBCol md="12">
                                            <MDBInput
                                                type="text"
                                                name="name"
                                                label="Username"
                                                value={this.state.name}
                                                onChange={this.changeHandler}
                                            required/>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>

                                        <MDBCol md="12">
                                            <MDBInput
                                                type="password"
                                                name="password"
                                                label="Password"
                                                value={this.state.password}
                                                onChange={this.changeHandler}
                                                required
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="12" className="text-center">
                                            <MDBBtn color="info" type="submit" rounded>{t('update-account')}</MDBBtn>
                                        </MDBCol>
                                    </MDBRow>
                                    </form>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        );
    }
}

export default withTranslation('common')(Profile);