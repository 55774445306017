export default function reducer(state = {
  question_type: null,
  questions_draft: [],
  questions_delete: [],
  questions_sequence: [],
  error: null
}, action = {}) {
  const { type, payload } = action;
  switch(type) {
    case 'MODIFY_QUESTION_SEQUENCE': {
      return {...state, questions_sequence: payload}
    }
    case 'MODIFY_QUESTION_CURRENT_TYPE': {
      return {...state, question_type: payload}
    }
    case 'MODIFY_QUESTIONS_DRAFT': {
      return {...state, questions_draft: payload}
    }
    case 'MODIFY_QUESTIONS_DELETE': {
      return {...state, questions_delete: payload}
    }
    case 'DROP_DRAFT_DATA': {
      return {...state, question_type: null, questions_draft: []}
    }
    case 'MODIFY_QUESTIONS_DRAFT_FAILURE': {
      return {...state, fetching: false, error: payload}
    }
    default: {
      return state;
    }
  }
}