import React, {Component} from 'react';
import { 
  MDBRow,
  MDBCol,
  MDBCard,
    MDBListGroup,
  MDBCardBody,
    MDBListGroupItem,
  MDBContainer,
    MDBDataTable
} from 'mdbreact';
import { withTranslation } from 'react-i18next';
import {Redirect} from 'react-router';
import '../../assets/css/v2.css';
import {loadState} from "../../storage/storage";
import BaseApi from "../../api/BaseApi";
import HomeLessonCategoryApi from "../../api/HomeLessonCategoryApi";
import DoctorApi from '../../api/DoctorApi';

class DoctorView extends Component{
    constructor(props) {
        super(props);
        this.state = {
            homeLessonCategories: null,
            homeLessons: null,
            isLogin: true,
            doctor: null,
        }
    }

    componentWillMount() {
        const token1 = loadState('token');
        if (token1) {
            BaseApi.authToken=token1;
        } else {
            this.setState({
                isLogin: false
            })
        }

    }

    async componentDidMount() {
        const homeLessons = [];
        
        let { doctor } = this.props.location;
        if(!doctor) {
            const { id } = this.props.match.params;
            const res = await DoctorApi.getOne(id);
            doctor = res.data;
        }
        const categories = await HomeLessonCategoryApi.getByDoctor(doctor.id);
        const homeLessonCategories = categories.map((category) => {
            category.homeLessons.forEach(lesson => {
                homeLessons.push({
                    id: lesson.id,
                    name: lesson.name,
                    category: category.name,
                    createdAt: lesson.createdAt,
                });
            })
            return {
                id: category.id,
                name: category.name,
                createdAt: category.createdAt,
            }
        });
        this.setState({homeLessonCategories, homeLessons, doctor});
    }
    render() {
        const {t}=this.props;
        const { doctor, isLogin, homeLessonCategories, homeLessons} = this.state;
        if(!doctor) {
            return null;
        }
        const categoryData = {
            columns: [
                {
                    label: t('id'),
                    field: 'id',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: t('name'),
                    field: 'name',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: t('created-at'),
                    field: 'createdAt',
                    sort: 'asc',
                    width: 100
                }
            ],
            rows: homeLessonCategories,
        };
        const lessonData = {
            columns: [
                {
                    label: t('id'),
                    field: 'id',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: t('name'),
                    field: 'name',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: "Category",
                    field: 'categeory',
                    sort: 'asc',
                    width: 150,
                },
                {
                    label: t('created-at'),
                    field: 'createdAt',
                    sort: 'asc',
                    width: 100
                }
            ],
            rows: homeLessons,
        };

        if (!isLogin) {
            return <Redirect to="/"/>
        }

        return (
            <div id="profile-v2" className="mb-5">
                <MDBContainer fluid>
                    <MDBRow>
                        <MDBCol lg="4" className="mb-4">
                            <MDBCard narrow>

                                <MDBCardBody className="text-center">
                                    <h3 className="text-muted"><strong>Doctor</strong>
                                    </h3>
                                    <MDBListGroup className="my-4 mx-4" >
                                        <MDBListGroupItem >ID: <strong>{doctor.id}</strong></MDBListGroupItem>
                                        <MDBListGroupItem >Name: <strong>{doctor.name}</strong></MDBListGroupItem>
                                        <MDBListGroupItem >Sign-up URL:  {doctor.signUpUrl}</MDBListGroupItem>
                                        <MDBListGroupItem >Questionnaire: <strong>{doctor.questionnaire.name}</strong></MDBListGroupItem>
                                        <MDBListGroupItem >CreatedAt: <strong>{doctor.createdAt}</strong></MDBListGroupItem>
                                    </MDBListGroup>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol lg="8" className="mb-r">
                            <MDBCard narrow>
                                <MDBCardBody>
                                    <h1 className="font-weight-bold">Home lesson categories</h1>
                                    <MDBDataTable
                                        entriesLabel={t("show-entries")}
                                        searchLabel={t("search")}
                                        infoLabel={[t("showing"), t("to"), t("of"), t("entries")]}
                                        paginationLabel={[t("previous"), t("next")]}
                                        striped
                                        hover
                                        data={categoryData}
                                        paging={true}
                                    />
                                </MDBCardBody>
                            </MDBCard>
                            <MDBCard narrow>
                                <MDBCardBody>
                                    <h1 className="font-weight-bold">Home lessons</h1>
                                    <MDBDataTable
                                        entriesLabel={t("show-entries")}
                                        searchLabel={t("search")}
                                        infoLabel={[t("showing"), t("to"), t("of"), t("entries")]}
                                        paginationLabel={[t("previous"), t("next")]}
                                        striped
                                        hover
                                        data={lessonData}
                                        paging={true}
                                    />
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        );
    }
}

export default withTranslation('common')(DoctorView);