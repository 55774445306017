import React from "react";
import {
  SideNavLink,
  SideNavCat,
  SideNavNav,
  SideNav,
  MDBIcon
} from "mdbreact";
import '../assets/css/custom.css';
import { withTranslation } from 'react-i18next';
const Link = require("react-router-dom").NavLink;

class SideNavigation extends React.Component {

  rSNL ( to, text ) {
    return <SideNavLink to={to} onClick={this.props.onLinkClick}>{text}</SideNavLink>
  }

  render() {
    const { t, onLinkClick } = this.props;
    return (
        <div className="white-skin">
          <SideNav
            logo="linus_logo.png"
            bg="https://mdbootstrap.com/img/Photos/Others/sidenav4.jpg"
            mask="strong"
            triggerOpening={this.props.triggerOpening}
            style={{transition: 'padding-left .3s'}}
          >

            <SideNavNav>
              <li><Link to="/dashboard" className="collapsible-header" onClick={onLinkClick}><MDBIcon icon="tachometer-alt"/> {t('dashboard')}</Link></li>
              <SideNavCat name= {t('profile')} id="profile-cat" icon="user">
                {this.rSNL("/profile", t('profile'))}
              </SideNavCat>
              <li><Link to="/doctors" className="collapsible-header" onClick={onLinkClick}><MDBIcon icon="address-book"/> {t('doctors')}</Link></li>
              <li><Link to="/patients" className="collapsible-header" onClick={onLinkClick}><MDBIcon icon="atlas"/> {t('patients')}</Link></li>
              <li><Link to="/questionnaires" className="collapsible-header" onClick={onLinkClick}><MDBIcon icon="bible"/> {t('questionnaires')}</Link></li>
              <li><Link to="/anamesis" className="collapsible-header" onClick={onLinkClick}><MDBIcon icon="file-alt"/> {t('anamnesis')}</Link></li>

            </SideNavNav>
          </SideNav>
        </div>
    );
  }
}

export default withTranslation('common')(SideNavigation);
