export function injectQuestionSequence(q) {
  return function(dispatch) {
    dispatch({
      'type': 'MODIFY_QUESTION_SEQUENCE',
      'payload': q
    });
  }
}

export function injectSelectedQuestionType(q) {
  return function(dispatch) {
    dispatch({
      'type': 'MODIFY_QUESTION_CURRENT_TYPE',
      'payload': q
    });
  }
}

export function injectQuestionToDraft(q) {
  return function(dispatch) {
    dispatch({
      'type': 'MODIFY_QUESTIONS_DRAFT',
      'payload': q
    });
  }
}

export function injectQuestionToDelete(q) {
  return function(dispatch) {
    dispatch({
      'type': 'MODIFY_QUESTIONS_DELETE',
      'payload': q
    });
  }
}

export function dropDraftData() {
  return function(dispatch) {
    dispatch({
      'type': 'DROP_DRAFT_DATA'
    });
  }
}