import React, {Component} from 'react';
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBContainer,
    MDBRow, MDBCol,
  MDBDataTable, MDBIcon
} from 'mdbreact/dist/mdbreact';
import { withTranslation } from 'react-i18next';
import BaseApi from "../../api/BaseApi";
import PatientApi from "../../api/PatientApi";
import {loadState} from "../../storage/storage";
import {Redirect} from 'react-router';
import moment from 'moment';
const Link = require("react-router-dom").NavLink;

class PatientList extends Component{
  constructor(props) {
    super(props);
    this.state = {
      date: new Date().toDateString(),
        rows: [],
        isLogin: true

    }
  }

    componentWillMount() {
        const token1 = loadState('token');
        if (token1) {
            BaseApi.authToken=token1;
        } else {
            this.setState({
                isLogin: false
            })
        }

    }

  componentDidMount() {
        console.log(this.props);
        this.initData();
    }

  initData() {
        let patients = [];
        PatientApi.getAll().then((res) => {
            if (res.data) {
                res.data.map((patient) => {
                    patients.push({
                        id: patient.id,
                        firstname: patient.firstName,
                        surname: patient.surName,
                        birth: patient.birthday,
                        gender: patient.gender,
                        clientID: patient.doctor.name,
                        created_at: moment(new Date(this.props.location.patient.createdAt)).format('MM.DD.YYYY hh:mm:ss'),
                        view: <Link to={{pathname:"/patients/view", patient:patient}}><MDBBtn color="pink" size="sm" rounded><MDBIcon icon="low-vision" className="pr-1"/></MDBBtn></Link>,
                    })
                });
                this.setState({
                    rows: patients
                })
            }
        });

    }



  render() {
    const {t} = this.props;
    const data = {
          columns: [
              {
                  label: t('id'),
                  field: 'id',
                  sort: 'asc',
                  width: 150
              },
              {
                  label: t('firstname'),
                  field: 'firstname',
                  sort: 'asc',
                  width: 150
              },
              {
                  label: t('surname'),
                  field: 'surname',
                  sort: 'asc',
                  width: 270
              },
              {
                  label: t('dateofbirth'),
                  field: 'birth',
                  sort: 'asc',
                  width: 200
              },
              {
                  label: t('gender'),
                  field: 'gender',
                  sort: 'asc',
                  width: 100
              },
              {
                  label: t('doctorid'),
                  field: 'clientID',
                  sort: 'abc',
                  width: 100
              },
              {
                  label: t('created-at'),
                  field: 'created_at',
                  sort: 'asc',
                  width: 100
              },
              {
                  label: t('view'),
                  field: 'view'
              }
          ],
          rows: this.state.rows
      };

      if (!this.state.isLogin) {
          return <Redirect to="/"/>
      }

    return (
        <MDBContainer className="mt-3">
            <MDBRow className="py-3">
                <MDBCol md="12">
                    <MDBCard>
                        <MDBCardBody>
                            <h2 className="h2-responsive pb-2 flex-1">{t('patients')}</h2>

                            <MDBDataTable
                                entriesLabel={t("show-entries")}
                                searchLabel={t("search")}
                                infoLabel={[t("showing"), t("to"), t("of"), t("entries")]}
                                paginationLabel={[t("previous"), t("next")]}
                                striped
                                hover
                                data= {data}
                            />
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
  }

}

export default withTranslation('common')(PatientList);
