import React, {Component} from 'react';
import { 
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBDataTable,
  MDBBtn,
  MDBIcon,
} from 'mdbreact/dist/mdbreact';
import {Redirect} from 'react-router';
import DoctorApi from '../../api/DoctorApi';
import {loadState} from "../../storage/storage";
import BaseApi from "../../api/BaseApi";
import { withTranslation } from 'react-i18next';
import Breadcrumbs from '@material-ui/lab/Breadcrumbs';
import moment from 'moment';
const Link = require("react-router-dom").NavLink;

class DoctorList extends Component{
  constructor(props) {
    super(props);
    this.state = {
      date: new Date().toDateString(),
      doctors: [],
        isLogin: true
    }
  }

  delete(index) {
      console.log('index', index);
      DoctorApi.remove({id: index.id}).then((res) => {
         console.log(res.data);
          this.initData();
      });
  }

  initData() {
    let doctors = [];
    DoctorApi.getAll().then((res) => {
        console.log('doctors', res.data);
        if (res.data) {
            res.data.map((doctor) => {
                doctors.push({
                    id: doctor.id,
                    name: doctor.name,
                    signUpUrl: doctor.signUpUrl,
                    created_at: moment(new Date(doctor.createdAt)).format('MM.DD.YYYY hh:mm:ss'),
                    view: <Link to={{pathname:`/doctors/view/${doctor.id}`, doctor:doctor}}><MDBBtn color="pink" size="sm" rounded><MDBIcon icon="low-vision" className="pr-1"/></MDBBtn></Link>,
                    edit: <Link to={{pathname:`/doctors/edit/${doctor.id}`, doctor:doctor}}><MDBBtn color="cyan" size="sm" rounded><MDBIcon far icon="edit" className="pr-1"/></MDBBtn></Link>,
                    delete: <MDBBtn color="dark-green" size="sm" rounded onClick={()=>this.delete(doctor)}><MDBIcon icon="archive" className="pr-1"/></MDBBtn>
                })
            })
        }
        this.setState({
            doctors:doctors
        });
    })
  }

    componentWillMount() {
        const token1 = loadState('token');
        if (token1) {
            BaseApi.authToken=token1;
        } else {
            this.setState({
                isLogin: false
            })
        }

    }

  componentDidMount() {
          this.initData();
  }

  render() {
    const {t}=this.props;
    const data = {
        columns: [
            {
                label: t('id'),
                field: 'id',
                sort: 'asc',
                width: 150
            },
            {
                label: t('name'),
                field: 'name',
                sort: 'asc',
                width: 150
            },
            {
                label: "Sign-up URL",
                field: 'signUpUrl',
                sort: 'asc',
                width: 150,
            },
            {
                label: t('created-at'),
                field: 'createdAt',
                sort: 'asc',
                width: 100
            },
            {
                label: t('view'),
                field: 'view'
            },
            {
                label: t('edit'),
                field: 'edit'
            },
            {
                label: t('delete'),
                field: 'delete'
            }
        ],
      rows: this.state.doctors
    };

      if (!this.state.isLogin) {
          return <Redirect to="/"/>
      }

    return (
        <MDBContainer>
              <Breadcrumbs aria-label="Breadcrumb">
                <Link color="primary" to="/" >
                    {t('home')}
                </Link>
                <Link color="inherit" to="/doctors" >
                    {t('user')}
                </Link>
              </Breadcrumbs>
          <MDBCard>
            <MDBCardBody>
              <h1 className="font-weight-bold">{t('doctors')}
                  <Link to={{pathname:"/doctors/create"}} >
                      <MDBBtn color="pink" size="md"  className="right-button">{t('new')}</MDBBtn>
                  </Link>
              </h1>
              <MDBDataTable
                  entriesLabel={t("show-entries")}
                  searchLabel={t("search")}
                  infoLabel={[t("showing"), t("to"), t("of"), t("entries")]}
                  paginationLabel={[t("previous"), t("next")]}
                  hover
                  data={data}
                  paging={true}
                  sortable={true}
              />
            </MDBCardBody>
          </MDBCard>
        </MDBContainer>
    );
  }
}

export default withTranslation('common')(DoctorList);
