import BaseApi from './BaseApi';

const AnswerApi = () => {

    const _api = BaseApi.api;

    const getAll = () => {
        return _api.get('/api/answers');
    };

    const getByAnamnesis = (anamnesisId) => {
        return _api.get(`/api/anamneses/${anamnesisId}/answers`);
    };

    const getOne = (id) => {
        return _api.get(`/api/answers/${id}`);
    };

    const create = (data) => {
        return _api.post('/api/answers', data);
    };

    const update = (data, id) => {
        return _api.put(`/api/answers/${id}`, data);
    };

    const remove = (id) => {
        return _api.delete(`/api/answers/${id}`);
    };

    return {
        getAll,
        getByAnamnesis,
        getOne,
        create,
        update,
        remove
    }

};

export default AnswerApi();
