import React from 'react';
import './assets/css/App.css';
import { Switch, Route } from 'react-router-dom';
import RoutesWithNavigation from './components/RoutesWithNavigation';
import Login from './pages/Login';
import Register from './pages/Register';
import { withTranslation } from 'react-i18next';

const App = () => {
    return (
          <Switch>
            <Route path='/' exact component={Login} />
            <Route path='/pages/login' exact component={Login} />
            <Route path='/pages/register' exact component={Register} />
            <RoutesWithNavigation/>
          </Switch>
    );
}


export default withTranslation('common')(App);
