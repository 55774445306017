import BaseApi from '../api/BaseApi';
import { toast } from 'mdbreact';
import uuid from 'uuid';
import { push } from 'connected-react-router';
const _api = BaseApi.api;

export function loadDoctors() {
  return function(dispatch) {
    _api.get('/api/doctors')
      .then((res) => {
        if (res.data) {
          dispatch({
            type: 'QUESTIONNAIRE_DOCTORS_SUCCESS',
            payload: res.data
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: 'DOCTORS_FAILURE',
          payload: error
        });
      });
  }
}

export function deleteQuestionnaire(id) {
  return function(dispatch) {
    _api.delete(`/api/questionnaires/${id}`)
      .then((res) => {
        dispatch({
          'type': 'QUESTIONNAIRE_LIST_SUCCESS',
          'payload': res.data
        });
      })
      .catch((error) => {
        dispatch({
          'type': 'QUESTIONNAIRE_LIST_FAILURE ',
          'payload': error.message
        });
      });
  }
}

export function loadQuestionnaireQuestionsEdit(id) {
  return function(dispatch) {
    _api.get('/api/question/questionnaire_by_id/' + id)
      .then((res) => {
        const questions = res.data.map(q => {
          return {
            sequence_id: q.id,
            sequence: q.order,
            abbreviation: q.abbreviation,
            content: q.content,
            createdAt: q.createdAt,
            qtype_id: q.type,
            label: (q.questionType && q.questionType.label) ? q.questionType.label : null,
            name: (q.questionType && q.questionType.name) ? q.questionType.name : null,
            options: q.question ? q.question.options : null,
            updatedAt: q.updatedAt,
            id: q.id,
            uuid: uuid.v4(),
            status: 2,
          };
        });
        questions.sort((a, b) => (a.sequence > b.sequence) ? 1 : -1);
        dispatch({
          'type': 'MODIFY_QUESTIONS_DRAFT',
          'payload': questions
        });
      })
      .catch((error) => {
        dispatch({
          'type': 'MODIFY_QUESTIONS_DRAFT_FAILURE ',
          'payload': error.message
        });
      });
  }
}

export function loadQuestionnaireQuestions(id) {
  return function(dispatch) {
    return _api.get('/api/question/questionnaire_by_id/' + id)
      .then((res) => {
        dispatch({
          'type': 'QUESTIONNAIRE_Q_SUCCESS',
          'payload': res.data
        });
      })
      .catch((error) => {
        dispatch({
          'type': 'QUESTIONNAIRE_Q_FAILURE ',
          'payload': error.message
        });
      });
  }
}

export function loadQuestionnaire(id) {
  return function(dispatch) {
    _api.get('/api/questionnaires/' + id)
      .then((res) => {
        dispatch({
          'type': 'QUESTIONNAIRE_SUCCESS',
          'payload': res.data
        });
      })
      .catch((error) => {
        dispatch({
          'type': 'QUESTIONNAIRE_FAILURE ',
          'payload': error.message
        });
      });
  }
}

export function loadQuestionnaires() {
  return function(dispatch) {
    _api.get('/api/questionnaires')
      .then((res) => {
        dispatch({
          'type': 'QUESTIONNAIRE_LIST_SUCCESS',
          'payload': res.data
        });
      })
      .catch((error) => {
        dispatch({
          'type': 'QUESTIONNAIRE_LIST_FAILURE ',
          'payload': error.message
        });
      });
  }
}

export function createQuestionnaire(data) {
  return function(dispatch) {
    _api.post('/api/questionnaires', data)
      .then((res) => {
        dispatch({
          'type': 'MODIFY_QUESTIONS_DRAFT',
          'payload': []
        });
        toast.success('Questionnaire successfully created');
      })
      .catch((error) => {
        toast.error('Server error');
      });
  }
}

export function editQuestionnaire(id, data) {
  return function(dispatch) {
    _api.put(`/api/questionnaires/${id}`, data)
      .then((res) => {
        toast.success('Questionnaire successfully edited');
        dispatch(push('/questionnaires'));
      })
      .catch((error) => {
        toast.error('Server error');
      });
  }
}