import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { MDBNavbar,
         MDBNavbarBrand,
         MDBNavbarNav,
         MDBNavItem,
         MDBNavLink,
         MDBIcon,
         MDBDropdown,
         MDBDropdownToggle,
         MDBDropdownMenu,
         MDBDropdownItem,
} from 'mdbreact';
import Translator from './translator';
import {Redirect} from 'react-router';
import {removeState} from "../storage/storage";
class TopNavigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: false,
            redirect: false
        };
        this.onClick = this.onClick.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleToggleClickA = this.handleToggleClickA.bind(this);
    }

    onClick(){
        this.setState({
            collapse: !this.state.collapse,
        });
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    handleToggleClickA() {
        this.props.onSideNavToggleClick();
    }

    removeToken(event) {
        event.preventDefault();
        removeState("token");
        console.log(this.props);
        this.setState({
            redirect: true
        })
    }


    render() {
        const navStyle = {
            paddingLeft:
              this.props.toggle ? '16px' : "240px",
              transition: 'padding-left .3s'
          };
        const {t} = this.props;
        if (this.state.redirect) {
            return <Redirect to="/"/>
        }
        return (
          <Router>
            <MDBNavbar className="flexible-MDBNavbar" light expand="md" scrolling fixed="top" style={{zIndex: 3}}>
                <div
                  onClick={this.handleToggleClickA}
                  key="sideNavToggleA"
                  style={{
                    lineHeight: "32px",
                    marginleft: "1em",
                    verticalAlign: "middle"
                  }}
                >
                  <MDBIcon icon="bars" color="white" size="lg" />
                </div>

                <MDBNavbarBrand href="/" style={navStyle}>
                    <strong>{this.props.routeName}</strong>
                </MDBNavbarBrand>

                <MDBNavbarNav expand="sm" right style={{flexDirection: 'row'}}>
                        <MDBNavItem>
                            <MDBNavLink to="/dashboard">
                                <MDBIcon icon="home"/>
                                <span className="d-none d-md-inline ml-1">{t('home')}</span>
                            </MDBNavLink>
                        </MDBNavItem>
                        <Translator/>
                        <MDBDropdown>
                            <MDBDropdownToggle nav caret>
                                    <MDBIcon icon="user"/> <span className="d-none d-md-inline">{t('profile')}</span>
                            </MDBDropdownToggle>
                            <MDBDropdownMenu right style={{minWidth: '200px'}}>
                                <MDBDropdownItem onClick={(event) => this.removeToken(event)}>
                                    {t('logout')}
                                </MDBDropdownItem>
                                <MDBDropdownItem href="/profile">
                                    {t('myaccount')}
                                </MDBDropdownItem>
                            </MDBDropdownMenu>
                        </MDBDropdown>
                </MDBNavbarNav>
            </MDBNavbar>
          </Router>
        );
    }
}

export default withTranslation('common')(TopNavigation);