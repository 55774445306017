import BaseApi from '../api/BaseApi';
import { toast } from 'mdbreact';
const _api = BaseApi.api; 

export function uploadBodyImage(formData, question_type, gender) {
  if (question_type) {
    return function(dispatch) {
      _api.post('/api/question_body_image', formData, {headers: {'Content-Type': 'multipart/form-data'}})
        .then((res) => {
          if (res.data) {
            const options = question_type.options || {};
            options.images = options.images || {};
            options.images[gender] = res.data.filename;
            let question_type_copy = { ...question_type, options: options};
            dispatch({
              'type': 'MODIFY_QUESTION_CURRENT_TYPE',
              'payload': question_type_copy
            });
          } else {
            toast.warning('Not enough data from API');
          }
        })
        .catch((error) => {
          toast.warning(error.message);
        });
    }
  } else {
    toast.warning('Question type is not selected');
  }
}

export function addQuestion(q) {
  return function(dispatch) {
    _api.get('/api/questiontype')
      .then((res) => {
        if (res.data) {
            dispatch({
              'type': 'QUESTION_TYPE_SUCCESS',
              'payload': res.data ? res.data : []
            });
        }
      })
      .catch((error) => {
        dispatch({
          'type': 'QUESTION_TYPE_FAILURE',
          'payload': error.message
        });
      });
  }
}

export function loadQuestionTypes() {
  return function(dispatch) {
    _api.get('/api/questiontype')
      .then((res) => {
        if (res.data) {
            dispatch({
              'type': 'QUESTION_TYPE_SUCCESS',
              'payload': res.data ? res.data : []
            });
        }
      })
      .catch((error) => {
        dispatch({
          'type': 'QUESTION_TYPE_FAILURE',
          'payload': error.message
        });
      });
  }
}