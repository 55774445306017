import BaseApi from './BaseApi';

const DoctorApi = () => {

    const _api = BaseApi.api;

    const getAll = () => {
        return _api.get('/api/anamneses');
    };

    const getByPatient = (patientId) => {
        return _api.get(`/api/doctors/${patientId}/anamneses`);
    }

    const getByDoctor = (doctorId) => {
        return _api.get(`/api/doctors/${doctorId}/anamneses`);
    };

    const getOne = (id) => {
        return _api.get(`/api/anamneses/${id}`);
    };

    const create = (data) => {
        return _api.post('/api/anamneses', data);
    };

    const update = (data, id) => {
        return _api.put(`/api/anamneses/${id}`, data);
    };

    const remove = (id) => {
        return _api.delete(`/api/anamneses/${id}`);
    };

    return {
        getAll,
        getByPatient,
        getByDoctor,
        getOne,
        create,
        update,
        remove
    }

};

export default DoctorApi();
