import React from 'react';
import { Route, Switch } from 'react-router-dom';

import DASHBOARD from '../pages/dashboard';
import Profile from '../pages/profile';
import DoctorList from "../pages/doctor/doctor-list";
import DoctorCreate from "../pages/doctor/doctor-create";
import DoctorEdit from "../pages/doctor/doctor-edit";
import DoctorView from "../pages/doctor/doctor-view";
import PatientList from "../pages/patient/patient-list";
import PatientView from "../pages/patient/patient-view";
import AnamesisList from "../pages/anamnesis/anamesis-list";
import AnamnesisView from "../pages/anamnesis/anamnesis-view";
import QuestionnaireList from "../pages/questionnaire/questionnaire-list";
import QuestionnaireCreate from "../pages/questionnaire/questionnaire-create";
import QuestionnaireEdit from "../pages/questionnaire/questionnaire-edit";
import QuestionnaireView from "../pages/questionnaire/questionnaire-view";


const fourtOFour = () => <h1 className="text-center">404</h1>

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <Route path='/dashboard'  component={DASHBOARD} />
        <Route path='/profile' exact component={Profile} />
        <Route path='/questionnaires' exact component={QuestionnaireList}/>
          <Route path='/questionnaires/create' exact component={QuestionnaireCreate} />
          <Route path='/questionnaires/edit/:id' exact component={QuestionnaireEdit} />
          <Route path='/questionnaires/view/:id' exact component={QuestionnaireView} />
        <Route path='/doctors' exact component={DoctorList}/>
          <Route path="/doctors/create" exact component={DoctorCreate} />
          <Route path="/doctors/edit/:id" exact component={DoctorEdit} />
          <Route path="/doctors/view/:id" exact component={DoctorView} />
        <Route path='/anamesis' exact component={AnamesisList}/>
          <Route path="/anamesis/view" exact component={AnamnesisView} />
        <Route path='/patients' exact component={PatientList}/>
          <Route path="/patients/view" exact component={PatientView} />
        <Route component={fourtOFour}/>
      </Switch>
    );
  }
}

export default Routes;