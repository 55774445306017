import BaseApi from './BaseApi';

export default class HomeLessonCategoryApi {
  static async getByDoctor(doctorId) {
    const response = await BaseApi.api.get(`/api/doctors/${doctorId}/homeLessonCategories`);
    return response.data;
  }

  static async updateAll(doctorId, categories) {
    const response = await BaseApi.api.patch(`/api/doctors/${doctorId}/homeLessonCategories`, categories);
    return response.data
  }
}