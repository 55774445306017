import BaseApi from '../api/BaseApi';
const _api = BaseApi.api; 

export function injectSelectedDoctorName(q) {
  return function(dispatch) {
    dispatch({
      'type': 'SELECTED_DOCTOR_NAME',
      'payload': q
    });
  }
}

export function loadDoctors() {
  return function(dispatch) {
    _api.get('/api/doctors')
      .then((res) => {
        if (res.data) {
          dispatch({
            type: 'DOCTORS_SUCCESS',
            payload: res.data
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: 'DOCTORS_FAILURE',
          payload: error
        });
      });
  }
}