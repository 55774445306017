import React, {Component} from 'react';
import { connect } from 'react-redux';
import { 
  MDBRow,
  MDBCol,
  MDBCard,
  MDBListGroupItem,
  MDBCardBody,
  MDBContainer,
  MDBListGroup,
  MDBDataTable
} from 'mdbreact';
import { withTranslation } from 'react-i18next';
import '../../assets/css/v2.css';
import BaseApi from "../../api/BaseApi";
import {loadState} from "../../storage/storage";
import {Redirect} from 'react-router';
import { loadQuestionnaire, loadQuestionnaireQuestions } from '../../actions/questionnaireActions';
class QuestionnaireView extends Component{
    constructor(props) {
        super(props);
        this.state = {
            questions: [],
            isLogin: true
        }
    }

    componentWillMount() {
        const token1 = loadState('token');
        if (token1) {
            BaseApi.authToken=token1;
        } else {
            this.setState({
                isLogin: false
            })
        }

    }

    componentDidMount() {
        const { id } = this.props.match.params;
        if (id) {
            this.props.loadQuestionnaire(id);
            this.props.loadQuestionnaireQuestions(id);
        }
    }

    render() {
        const { t, questionnaire_data } = this.props;
        const questions = questionnaire_data.questions.map(q => {
            return {
                id: q.id,
                type: q.questionType ? q.questionType.label : 'not defined',
                abbreviation: q.abbreviation,
                content: q.content,
                date: q.createdAt
            };
        });
        const data = {
            columns: [
                {
                    label: t('id'),
                    field: 'id',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: t('type'),
                    field: 'type',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: t('abbreviation'),
                    field: 'abbreviation',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: t('content'),
                    field: 'content',
                    sort: 'asc',
                    width: 270
                },
                {
                    label: t('created-at'),
                    field: 'created_at',
                    sort: 'asc',
                    width: 100
                }
            ],
            rows: questions
        };

        if (!this.state.isLogin) {
            return <Redirect to="/"/>
        }

        return (
            <div id="profile-v2" className="mb-5">
                <MDBContainer fluid>
                    <MDBRow>
                        <MDBCol lg="4" className="mb-4">
                            <MDBCard narrow>

                                <MDBCardBody className="text-center">
                                    <h3 className="text-muted"><strong>Questionnaire</strong>
                                    </h3>
                                    <MDBListGroup className="my-4 mx-4" >
                                        <MDBListGroupItem >ID: <strong>{questionnaire_data.questionnaire.id ? questionnaire_data.questionnaire.id : 'not defined'}</strong></MDBListGroupItem>
                                        <MDBListGroupItem >Name: <strong>{questionnaire_data.questionnaire.name ? questionnaire_data.questionnaire.name : 'not defined'}</strong></MDBListGroupItem>
                                        <MDBListGroupItem >Doctor Name: <strong>{questionnaire_data.questionnaire.doctor ? questionnaire_data.questionnaire.doctor.name : 'not defined'}</strong></MDBListGroupItem>
                                        <MDBListGroupItem >CreatedAt: <strong>{questionnaire_data.questionnaire.createdAt ? questionnaire_data.questionnaire.createdAt : 'not defined'}</strong></MDBListGroupItem>
                                    </MDBListGroup>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol lg="8" className="mb-r">
                            <MDBCard narrow>
                                <MDBCardBody>
                                    <h1 className="font-weight-bold">{t('question')}</h1>
                                    <MDBDataTable
                                        entriesLabel={t("show-entries")}
                                        searchLabel={t("search")}
                                        infoLabel={[t("showing"), t("to"), t("of"), t("entries")]}
                                        paginationLabel={[t("previous"), t("next")]}
                                        striped
                                        hover
                                        data={data}
                                        paging={true}
                                    />
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
  const {
    questionnaire_data
  } = state
  return {
    questionnaire_data
  }
};
export default connect(mapStateToProps, {
  loadQuestionnaire,
  loadQuestionnaireQuestions
})(withTranslation('common')(QuestionnaireView));
