import BaseApi from './BaseApi';

const DoctorApi = () => {

    const _api = BaseApi.api;

    const getAll = () => {
        return _api.get('/api/doctors');
    };

    const getOne = (id) => {
        return _api.get('/api/doctors/'+id);
    };

    const create = (data) => {
        return _api.post('/api/doctors', data);
    };

    const update = (data, id) => {
        return _api.put('/api/doctors/'+id, data);
    };

    const remove = (id) => {
        return _api.delete('/api/doctors', id);
    };

    return {
        getAll,
        getOne,
        create,
        update,
        remove
    }

};

export default DoctorApi();
