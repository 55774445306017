export default function reducer(state = {
  questionnaire_doctors: [],
  questionnaire_list: [],
  questionnaire: {},
  questions: [],
  fetching: true,
  fetched: false,
  questionnaire_list_error: null,
  questionnaire_error: null,
  questions_error: null
}, action = {}) {
  const {type, payload} = action
  switch(type) {
    case 'QUESTIONNAIRE_DOCTORS_SUCCESS': {
      return {...state, fetching: false, fetched: true, questionnaire_doctors: payload}
    }
    case 'QUESTIONNAIRE_Q_SUCCESS': {
      return {...state, fetching: false, fetched: true, questions: payload}
    }
    case 'QUESTIONNAIRE_LIST_SUCCESS': {
      return {...state, fetching: false, fetched: true, questionnaire_list: payload}
    }
    case 'QUESTIONNAIRE_LIST_FAILURE': {
      return {...state, fetching: false, questionnaire_list_error: payload}
    }
    case 'QUESTIONNAIRE_SUCCESS': {
      return {...state, fetching: false, fetched: true, questionnaire: payload}
    }
    case 'QUESTIONNAIRE_FAILURE': {
      return {...state, fetching: false, questionnaire_error: payload}
    }
    case 'QUESTIONNAIRE_Q_FAILURE': {
      return {...state, fetching: false, questions_error: payload}
    }
    case 'QUESTIONNAIRE_DOCTORS_FAILURE': {
      return {...state, fetching: false, questions_error: payload}
    }
    default: {
      return state;
    }
  }
}