import React from 'react';
import { MDBFooter } from 'mdbreact';
import { useTranslation } from 'react-i18next';
const Copyrights = (props) => {
  const date = new Date().getFullYear();
  const {t} = useTranslation('common')
  return (
    <MDBFooter className={props.className} style={{...props.style, zIndex: 2}}>
      <p className="footer-copyright mb-0 py-3 text-center">
        &copy; {date} {t('general-manager')}: Werner Osteo
      </p>
    </MDBFooter>
  )
}
export default Copyrights