import React, {Component} from 'react';
import { connect } from 'react-redux';
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBDataTable, MDBIcon
} from 'mdbreact/dist/mdbreact'
import { withTranslation } from 'react-i18next';
import "../../assets/css/custom.css"
import BaseApi from "../../api/BaseApi";
import {loadState} from "../../storage/storage";
import {Redirect} from 'react-router';
import { loadQuestionnaires, deleteQuestionnaire } from '../../actions/questionnaireActions';
const Link = require("react-router-dom").NavLink;

class QuestionnaireList extends Component{
  constructor(props) {
    super(props);
    this.state = {
      isLogin: true
    };
  }

  componentWillMount() {
      const token1 = loadState('token');
      if (token1) {
          BaseApi.authToken=token1;
      } else {
          this.setState({
              isLogin: false
          })
      }
  }

  componentDidMount() {
    this.props.loadQuestionnaires();
  }

  delete(index) {
    this.props.deleteQuestionnaire(index.id);
  }

  render() {
    const { t, questionnaire_data } = this.props;
    const tableData = questionnaire_data.questionnaire_list.map(d => {
      return {
        id: d.id,
        name: d.name,
        clientID: d.doctor? d.doctor.name: d.doctor,
        date: new Date(d.createdAt).toLocaleString(),
        view: <Link to={'/questionnaires/view/' + d.id}><MDBBtn color="pink" size="sm" rounded><MDBIcon icon="low-vision" className="pr-1"/></MDBBtn></Link>,
        edit: <Link to={'/questionnaires/edit/' + d.id}><MDBBtn color="cyan" size="sm" rounded ><MDBIcon far icon="edit" className="pr-1"/></MDBBtn></Link>,
        delete: <MDBBtn color="dark-green" size="sm" rounded onClick={()=>this.delete(d)}><MDBIcon icon="archive" className="pr-1"/></MDBBtn>
      };
    });
    const data = {
      columns: [
        {
          label: t('id'),
          field: 'id',
          sort: 'asc',
          width: 150
        },
        {
          label: t('name'),
          field: 'name',
          sort: 'asc',
          width: 150
        },
        {
          label: t('clientid'),
          field: 'clientID',
          sort: 'asc',
          width: 270
        },
        {
          label: t('created-at'),
          field: 'created_at',
          sort: 'asc',
          width: 100
        },
        {
          label: t('view'),
          field: 'view'
        },
        {
          label: t('edit'),
          field: 'edit'
        },
        {
          label: t('delete'),
          field: 'delete'
        }
      ],
      rows: tableData
    };
    if (!this.state.isLogin) {
      return <Redirect to="/"/>
    }
    return (
        <MDBContainer>
          <MDBCard>
            <MDBCardBody>
                <h1 className="font-weight-bold">{t('questionnaire')}
                  <Link to={{pathname:"/questionnaires/create", user:"200091"}} >
                      <MDBBtn color="pink" size="md"  className="right-button">{t('new')}</MDBBtn>
                  </Link>
                </h1>

              <MDBDataTable
                  entriesLabel={t("show-entries")}
                  searchLabel={t("search")}
                  infoLabel={[t("showing"), t("to"), t("of"), t("entries")]}
                  paginationLabel={[t("previous"), t("next")]}
                  striped
                  hover
                  data={data}
                  paging={true}
              />
            </MDBCardBody>
          </MDBCard>
        </MDBContainer>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    questionnaire_data
  } = state
  return {
    questionnaire_data
  }
};
export default connect(mapStateToProps, {
  loadQuestionnaires,
  deleteQuestionnaire
})(withTranslation('common')(QuestionnaireList));