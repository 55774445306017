import React, {Component} from 'react';
import { 
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBContainer,
  MDBBtn,
  MDBIcon,
  MDBDataTable,
  toast,
  ToastContainer,
} from 'mdbreact';
import Select from 'react-select';
import {Redirect} from 'react-router';
import { withTranslation } from 'react-i18next';
import initialHomeLessonCategories from './initialHomeLessonCategories.json';
import DoctorApi from '../../api/DoctorApi';
import HomeLessonCategoryApi from "../../api/HomeLessonCategoryApi";
import {loadState} from "../../storage/storage";
import BaseApi from "../../api/BaseApi";

class DoctorCreate extends Component{

    constructor(props) {
        super(props);
        const homeLessonCategories = initialHomeLessonCategories;
        const lessonsData = [];
        const categoriesData = homeLessonCategories.map((category, categoryIndex) => {
            category.homeLessons.forEach((lesson, lessonIndex) => {
                lessonsData.push({
                    id: null,
                    name: lesson.name,
                    category: category.name,
                    createdAt: 'new',
                    delete: (
                        <MDBBtn 
                            color="dark-green" 
                            size="sm" 
                            rounded 
                            onClick={() => this.deleteLesson(categoryIndex, lessonIndex)}
                        >
                            <MDBIcon icon="archive" className="pr-1"/>
                        </MDBBtn>
                    ),
                });
            })
            return {
                id: null,
                name: category.name,
                createdAt: 'new',
                delete: (
                    <MDBBtn 
                        color="dark-green" 
                        size="sm" 
                        rounded 
                        onClick={() => this.deleteCategory(categoryIndex)}
                    >
                        <MDBIcon icon="archive" className="pr-1"/>
                    </MDBBtn>
                ),
            }
        });

        this.state = {
            name: "",
            categoriesData,
            lessonsData,
            homeLessonCategories,
            selectedCategory: null,
            newCategory: "",
            newLesson: "",
            redirect: false,
            isLogin: true
        };
    }
    

    submitHandler = async event => {
        event.preventDefault();
        event.target.className += " was-validated";
        let doctor = {
            name: this.state.name,
            role: "Doctor"
        }
        try {
            const res = await DoctorApi.create(doctor);
            if (res.data) {
                await HomeLessonCategoryApi.updateAll(res.data.id, this.state.homeLessonCategories);
                console.log("created doctor", res.data);
                this.setState({redirect: true})
            }
            
        } catch(err) {
            console.log(err);
        }
    };

    addCategory = () => {
        const { homeLessonCategories, categoriesData, newCategory } = this.state;
        if(newCategory === "") {
            toast.warn('Please insert name.');
            return;
        }
        this.setState({ 
            homeLessonCategories: [...homeLessonCategories, { name: newCategory, homeLessons: [] }],
            categoriesData: [
                ...categoriesData, 
                { 
                    id: null, 
                    name: newCategory, 
                    createdAt: "new",
                    delete: (
                        <MDBBtn 
                            color="dark-green" 
                            size="sm" 
                            rounded 
                            onClick={() => this.deleteCategory(homeLessonCategories.length)}
                        >
                            <MDBIcon icon="archive" className="pr-1"/>
                        </MDBBtn>
                    ),
                }
            ], 
        });
        toast.success("Added a new category.");
    }

    selectCategory = event => {
        const { value, label } = event;
        this.setState({
            selectedCategory: { value, label },
        })
    }

    addLesson = () => {
        const { homeLessonCategories, lessonsData, selectedCategory, newLesson} = this.state;
        if(newLesson === "") {
            toast.warn('Please insert name.');
            return;
        }
        if(selectedCategory === null) {
            toast.warn('Please choose category.');
            return;
        }
        const homeLessonCategoriesCopy = [ ...homeLessonCategories ];
        const category = homeLessonCategoriesCopy[selectedCategory.value];
        category.homeLessons.push({
            name: newLesson,
        });
        this.setState({
            homeLessonCategories: homeLessonCategoriesCopy,
            lessonsData: [
                ...lessonsData, 
                { 
                    id: null, 
                    name: newLesson, 
                    category: category.name, 
                    createdAt: "new",
                    delete: (
                        <MDBBtn 
                            color="dark-green" 
                            size="sm" 
                            rounded 
                            onClick={() => this.deleteLesson(selectedCategory.value, category.homeLessons.length - 1)}
                        >
                            <MDBIcon icon="archive" className="pr-1"/>
                        </MDBBtn>
                    ),
                }
            ],
        });
        toast.success("Added a new lesson.");
    }

    deleteCategory = (categoryDeleteIndex) => {
        const { homeLessonCategories } = this.state;
        const lessonsData = [];
        const categoriesData = [];
        const homeLessonCategoriesCopy = [];
        let shift = 0;
        homeLessonCategories.forEach((category, categoryIndex) => {
            if(categoryIndex === categoryDeleteIndex) {
                if(category.id) {
                    homeLessonCategoriesCopy.push({
                        ...category,
                        deleted: true,
                    })
                } else {
                    shift = 1;
                }
            } else {
                if(category.deleted) {
                    return;
                }
                category.homeLessons.forEach((lesson, lessonIndex) => {
                    if(lesson.deleted) {
                        return;
                    }
                    lessonsData.push({
                        id: lesson.id,
                        name: lesson.name,
                        category: category.name,
                        createdAt: lesson.createdAt,
                        delete: (
                            <MDBBtn 
                                color="dark-green" 
                                size="sm" 
                                rounded 
                                onClick={() => this.deleteLesson(categoryIndex-shift, lessonIndex)}
                            >
                                <MDBIcon icon="archive" className="pr-1"/>
                            </MDBBtn>
                        ),
                    });
                })
                categoriesData.push({
                    id: category.id,
                    name: category.name,
                    createdAt: category.createdAt,
                    delete: (
                        <MDBBtn 
                            color="dark-green" 
                            size="sm" 
                            rounded 
                            onClick={() => this.deleteCategory(categoryIndex-shift)}
                        >
                            <MDBIcon icon="archive" className="pr-1"/>
                        </MDBBtn>
                    ),
                });
                homeLessonCategoriesCopy.push(category);
            }
        });
        this.setState({
            homeLessonCategories: homeLessonCategoriesCopy,
            lessonsData,
            categoriesData,
        })
    }

    deleteLesson = (categoryDeleteIndex, lessonDeleteIndex) => {
        const { homeLessonCategories } = this.state;
        const lessonsData = [];
        const categoriesData = [];
        const homeLessonCategoriesCopy = [...homeLessonCategories];
        homeLessonCategoriesCopy.forEach((category, categoryIndex) => {
            if(category.deleted) {
                return;
            }
            if(categoryIndex === categoryDeleteIndex) {
                if(category.homeLessons[lessonDeleteIndex].id) {
                    category.homeLessons[lessonDeleteIndex].deleted = true;
                } else {
                    category.homeLessons = [
                        ...category.homeLessons.slice(0, lessonDeleteIndex),
                        ...category.homeLessons.slice(lessonDeleteIndex+1),
                    ]
                }
            }
            category.homeLessons.forEach((lesson, lessonIndex) => {
                if(lesson.deleted) {
                    return;
                }
                lessonsData.push({
                    id: lesson.id,
                    name: lesson.name,
                    category: category.name,
                    createdAt: lesson.createdAt,
                    delete: (
                        <MDBBtn 
                            color="dark-green" 
                            size="sm" 
                            rounded 
                            onClick={() => this.deleteLesson(categoryIndex, lessonIndex)}
                        >
                            <MDBIcon icon="archive" className="pr-1"/>
                        </MDBBtn>
                    ),
                });
            })
            categoriesData.push({
                id: category.id,
                name: category.name,
                createdAt: category.createdAt,
                delete: (
                    <MDBBtn 
                        color="dark-green" 
                        size="sm" 
                        rounded 
                        onClick={() => this.deleteCategory(categoryIndex)}
                    >
                        <MDBIcon icon="archive" className="pr-1"/>
                    </MDBBtn>
                ),
            });
        });
        this.setState({
            homeLessonCategories: homeLessonCategoriesCopy,
            lessonsData,
            categoriesData,
        })
    }

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    componentWillMount() {
        const token1 = loadState('token');
        if (token1) {
            BaseApi.authToken=token1;
        } else {
            this.setState({
                isLogin: false
            })
        }

    }

    componentDidMount() {

    }

    render() {
        const {t}=this.props;
        const { selectedCategory, isLogin, redirect, lessonsData, categoriesData, homeLessonCategories } = this.state;
        const categoryData = {
            columns: [
                {
                    label: t('id'),
                    field: 'id',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: t('name'),
                    field: 'name',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: t('created-at'),
                    field: 'createdAt',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: t('delete'),
                    field: 'delete'
                }
            ],
            rows: categoriesData,
        };
        const lessonData = {
            columns: [
                {
                    label: t('id'),
                    field: 'id',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: t('name'),
                    field: 'name',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: "Category",
                    field: 'categeory',
                    sort: 'asc',
                    width: 150,
                },
                {
                    label: t('created-at'),
                    field: 'createdAt',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: t('delete'),
                    field: 'delete'
                }
            ],
            rows: lessonsData,
        };
        const categoryOptions = [];
        homeLessonCategories.forEach((category, index) => {
            if(!category.deleted) {
                categoryOptions.push({
                    label: category.name,
                    value: index,
                });
            }
        });

        if(redirect){
            return(
                <Redirect to={"/doctors"} />
            )
        } else if (!isLogin) {
            return <Redirect to="/"/>
        }
        return (
            <div id="profile-v2" className="mb-5">
                <ToastContainer
                    hideProgressBar={true}
                    newestOnTop={true}
                    autoClose={5000}
                />
                <MDBContainer fluid>
                    <MDBRow>
                        <MDBCol lg="2" className="mb-2">
                        </MDBCol>
                        <MDBCol lg="8" className="mb-r">
                            <MDBCard narrow>
                                <MDBCardBody className="text-center">
                                    <h1 className="font-weight-bold">{t('new_doctor')}</h1>
                                    <form
                                        className="needs-validation"
                                        onSubmit={this.submitHandler}
                                        noValidate
                                    >
                                        <MDBRow center>
                                            <MDBCol md="7">
                                                <MDBInput
                                                    value={this.state.name}
                                                    name="name"
                                                    onChange={this.changeHandler}
                                                    type="text"
                                                    id="materialFormRegisterNameEx"
                                                    label={t("name")}
                                                    required
                                                >

                                                </MDBInput>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBBtn color="success" type="submit">
										{t('Create')}
                                        </MDBBtn>
                                    </form>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol lg="4" className="mb-4">
                            <MDBCard narrow>
                                <MDBCardBody className="text-center">
                                    <h3 className="text-muted"><strong>New Home lesson category</strong></h3>
                                    <div className="question_content">
                                        <label className="question_content__title">Name</label>
                                        <div className="question_content__body">
                                            <div className="md-form">
                                                <input name="newCategory" type="text" className="form-control" onChange={this.changeHandler} />
                                            </div>
                                        </div>
                                    </div>
                                    <MDBRow center>
                                        <MDBBtn color="danger" type="submit" rounded size="sm" onClick={this.addCategory}>Add</MDBBtn>
                                    </MDBRow>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol lg="8" className="mb-r">
                            <MDBCard narrow>
                                <MDBCardBody>
                                    <h1 className="font-weight-bold">Home lesson categories</h1>
                                    <MDBDataTable
                                        entriesLabel={t("show-entries")}
                                        searchLabel={t("search")}
                                        infoLabel={[t("showing"), t("to"), t("of"), t("entries")]}
                                        paginationLabel={[t("previous"), t("next")]}
                                        striped
                                        hover
                                        data={categoryData}
                                        paging={true}
                                    />
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol lg="4" className="mb-4">
                            <MDBCard narrow>
                                <MDBCardBody className="text-center">
                                    <h3 className="text-muted"><strong>New Home lesson</strong></h3>
                                    <Select
                                        value={selectedCategory}
                                        placeholder="Choose home lesson category"
                                        options={categoryOptions}
                                        onChange={this.selectCategory}
                                        className={'question_select_custom'}
                                        name='question_type_choose'
                                    />
                                    <div className="question_content">
                                        <label className="question_content__title">Name</label>
                                        <div className="question_content__body">
                                            <div className="md-form">
                                                <input name="newLesson" type="text" className="form-control" onChange={this.changeHandler} />
                                            </div>
                                        </div>
                                    </div>
                                    <MDBRow center>
                                        <MDBBtn color="danger" type="submit" rounded size="sm" onClick={this.addLesson}>Add</MDBBtn>
                                    </MDBRow>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol lg="8" className="mb-r">
                            <MDBCard narrow>
                                <MDBCardBody>
                                    <h1 className="font-weight-bold">Home lessons</h1>
                                    <MDBDataTable
                                        entriesLabel={t("show-entries")}
                                        searchLabel={t("search")}
                                        infoLabel={[t("showing"), t("to"), t("of"), t("entries")]}
                                        paginationLabel={[t("previous"), t("next")]}
                                        striped
                                        hover
                                        data={lessonData}
                                        paging={true}
                                    />
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        );
    }
}

export default withTranslation('common')(DoctorCreate);