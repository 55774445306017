import React, {Component} from 'react';
import Select from 'react-select';
import ImageUploader from 'react-images-upload';
import { connect } from 'react-redux';
import uuid from 'uuid';
import { 
  MDBRow,
  MDBCol,
  MDBCard,
  MDBDataTable,
  MDBCardBody,
  MDBContainer,
  MDBBtn,
  MDBIcon,
  ToastContainer,
  toast
} from 'mdbreact';
import ApiConfig from '../../config/ApiConfig';
import {Redirect} from 'react-router';
import BaseApi from '../../api/BaseApi';
import { withTranslation } from 'react-i18next';
import '../../assets/css/v2.css';
import { loadState } from "../../storage/storage";
import { loadQuestionTypes, uploadBodyImage } from '../../actions/questionActions';
import { loadDoctors, injectSelectedDoctorName } from '../../actions/doctorActions';
import { injectSelectedQuestionType, injectQuestionToDraft, injectQuestionToDelete, dropDraftData } from '../../actions/draftDataActions';
import { loadQuestionnaire, loadQuestionnaireQuestionsEdit } from '../../actions/questionnaireActions';
import { editQuestionnaire } from '../../actions/questionnaireActions';

class QuestionnaireEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            isLogin: true
        };

    }

    componentWillMount() {
        const token1 = loadState('token');
        if (token1) {
            BaseApi.authToken=token1;
        } else {
            this.setState({
                isLogin: false
            })
        }
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.props.loadQuestionTypes();
        this.props.loadDoctors();
        this.props.loadQuestionnaire(id); // should fill a draft data
        this.props.loadQuestionnaireQuestionsEdit(id); // should fill a draft data
    }

    handleSelectedDoctor = (e) => {
        const name = e.value;
        this.props.injectSelectedDoctorName(name);
    }

    selectQuestionType = (e) => {
        const { question_types_list, injectSelectedQuestionType } = this.props;
        const id = e.value;
        const currentQuestionType = question_types_list.data.find(q => q.id === id);
        injectSelectedQuestionType(currentQuestionType);
    }

    dropQuestionType() {
        const { injectSelectedQuestionType } = this.props;
        injectSelectedQuestionType(null);
    }

    triggerQuestionOptionImage = (gender) => {
        return (files) => {
            const { draft_data } = this.props;
            const question_type = draft_data.question_type ? draft_data.question_type : null;
            const formData = new FormData();
            formData.append("file", files[0]);
            this.props.uploadBodyImage(formData, question_type, gender);
        }
    }

    triggerQuestionOption(event) {
        const { draft_data } = this.props;
        const question_type_id = draft_data.question_type ? draft_data.question_type.id : 0;
        let questionTypeOptions = null;
        const value = event.target ? event.target.value : event.value;
        switch(question_type_id) {
            case 3: {
                questionTypeOptions = {
                    number: value < 1 ? 1 : parseInt(value)
                };
                break;
            }
            case 4: {
                questionTypeOptions = {
                    trigger: value
                };
                break;
            }
            default: {
                questionTypeOptions = null;
            }
        }
        if (questionTypeOptions) {
            let question_type_copy = { ...draft_data.question_type, options: questionTypeOptions};
            this.props.injectSelectedQuestionType(question_type_copy);
        }
    }

    triggerQuestionContent(event) {
        const value = event.target.value;
        const { draft_data, injectSelectedQuestionType } = this.props;
        let question_type_copy = { ...draft_data.question_type, content: value};
        injectSelectedQuestionType(question_type_copy);
    }

    triggerQuestionAbbreviation = (event) => {
        const value = event.target.value;
        const { draft_data, injectSelectedQuestionType } = this.props;
        let question_type_copy = { ...draft_data.question_type, abbreviation: value};
        injectSelectedQuestionType(question_type_copy);
    }

    onToggleFiles = (event) => {
        const value = event.target.value;
        const { draft_data, injectSelectedQuestionType } = this.props;
        const question_type_copy = { ...draft_data.question_type }
        if(value === 'yes') {
            if(!question_type_copy.options) {
                question_type_copy.options = {};
            }
            question_type_copy.options.allowFiles = true;
        } else {
            if(question_type_copy.options) {
                delete question_type_copy.options.allowFiles;
                if(Object.keys(question_type_copy.options).length === 0) {
                    question_type_copy.options = null;
                }
            }
        }
        injectSelectedQuestionType(question_type_copy);
    }

    onChangeGender = (event) => {
        const { checked, name } = event.target;
        const { draft_data, injectSelectedQuestionType } = this.props;
        const question_type_copy = { ...draft_data.question_type }
        if(checked) {
            if(!question_type_copy.options) {
                question_type_copy.options = {};
            }
            if(!question_type_copy.options.excludedGenders) {
                question_type_copy.options.excludedGenders = {};
            }
            question_type_copy.options.excludedGenders[name] = true;
        } else {
            if(question_type_copy.options) {
                if(question_type_copy.options.excludedGenders) {
                    delete question_type_copy.options.excludedGenders[name];
                    if(Object.keys(question_type_copy.options.excludedGenders).length === 0) {
                        delete question_type_copy.options.excludedGenders;
                        if(Object.keys(question_type_copy.options).length === 0) {
                            question_type_copy.options = null;
                        }
                    }
                }
            }
        }
        injectSelectedQuestionType(question_type_copy);
    }

    deleteQuestion = (question) => {
        const { draft_data, injectQuestionToDraft, injectQuestionToDelete } = this.props;
        const uuid = question.uuid ? question.uuid : null;
        const id = question.id ? question.id : null;
        let question_draft_copy = [...draft_data.questions_draft];
        const removeIndex = question_draft_copy.findIndex(q => q.uuid === uuid);
        if (removeIndex !== -1) {
            question_draft_copy.splice(removeIndex, 1);
            injectQuestionToDraft(question_draft_copy);
        }
        if (typeof question.status !== 'undefined' && question.status === 2) {
            let questions_delete_copy = [...draft_data.questions_delete];
            if (questions_delete_copy.indexOf(id) === -1) {
                questions_delete_copy.push(id);
            }
            injectQuestionToDelete(questions_delete_copy);
        }
    }

    changeQuestionSequence = (id, e) => {
        const { draft_data, injectQuestionToDraft } = this.props;
        let question_draft_copy = [...draft_data.questions_draft];
        const index = question_draft_copy.findIndex(q => q.id === id);
        const sequence = parseInt(e.target.value);
        if (index !== -1) {
            question_draft_copy[index].sequence = sequence;
            injectQuestionToDraft(question_draft_copy);
        }
    }

    addQuestion = () => {
        const { draft_data, injectQuestionToDraft, injectSelectedQuestionType } = this.props;
        if (draft_data.question_type) {
            if (draft_data.question_type.content && draft_data.question_type.content.trim() !== "") {
                let question_draft_copy = [...draft_data.questions_draft];
                question_draft_copy.sort((a, b) => (a.sequence > b.sequence) ? 1 : -1);
                const sequence = question_draft_copy[question_draft_copy.length - 1].sequence + 1;
                let question_type_copy = { ...draft_data.question_type, uuid: uuid.v4(), sequence};
                question_draft_copy.push(question_type_copy);
                injectQuestionToDraft(question_draft_copy);
                injectSelectedQuestionType(null);
            } else {
                toast.warn('Please insert question content');
                return;
            }
        } else {
            toast.warn('Question type is not selected');
            return;
        }
    };

    save = () => {
        const { draft_data, questionnaire_data, editQuestionnaire } = this.props;
        const questionnaireName = this.refs.questionnaires_name_ref.value;
        const questions = draft_data.questions_draft;
        const questionnaire = questionnaire_data.questionnaire;
        const questions_delete = draft_data.questions_delete;
        if (questionnaireName === "") {
            toast.warn('Please insert name.');
            return;
        } else if (questions.length === 0) {
            toast.warn('Please inject questions.');
            return;
        }
        editQuestionnaire(questionnaire.id, {questionnaireName, questions, questions_delete});
        document.getElementById('questionnaires_name').value = "";
    }

    questionTypeOptionsContent() {
        const { t, draft_data } = this.props;
        const question_type = draft_data.question_type ? draft_data.question_type : null;
        const id = question_type ? question_type.id : 0;
        switch(id) {
            case 3: {
                return (
                    <div>
                        <label className="question_content__title">{t('Multitext fields count')} </label>
                        <div className="question_content__body">
                            <div className="md-form">
                                <input type="number" className="form-control" defaultValue="1" min="1" max="10" onChange={this.triggerQuestionOption.bind(this)} />
                            </div>
                        </div>
                    </div>
                );
            }
            case 4: {
                return null;
            }
            case 5: {
                const baseURL = process.env.NODE_ENV === 'production'? ApiConfig.baseProURL: ApiConfig.baseDevURL;
                const previewImages = (question_type && question_type.options && question_type.options.images) ? question_type.options.images : {}; 
                const getURL = (name) => `${baseURL}/static/body_images/${name}`;
                
                return (
                    <div>
                        <label className="question_content__title">{`${t('bodyImage')}: ${t('male')}`}</label>
                        <div className="question_content__body">
                            <div className="body_image_preview">
                                { previewImages.male ? <img src={getURL(previewImages.male)} alt=""/> : null }
                            </div>
                            <ImageUploader
                                withIcon={true}
                                buttonText='Choose images'
                                onChange={this.triggerQuestionOptionImage('male')}
                                imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                maxFileSize={5242880}
                            />
                        </div>
                        <label className="question_content__title">{`${t('bodyImage')}: ${t('female')}`}</label>
                        <div className="question_content__body">
                            <div className="body_image_preview">
                                { previewImages.female ? <img src={getURL(previewImages.female)} alt=""/> : null }
                            </div>
                            <ImageUploader
                                withIcon={true}
                                buttonText='Choose images'
                                onChange={this.triggerQuestionOptionImage("female")}
                                imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                maxFileSize={5242880}
                            />
                        </div>
                        <label className="question_content__title">{`${t('bodyImage')}: ${t('diverse')}`}</label>
                        <div className="question_content__body">
                            <div className="body_image_preview">
                                { previewImages.diverse ? <img src={getURL(previewImages.diverse)} alt=""/> : null }
                            </div>
                            <ImageUploader
                                withIcon={true}
                                buttonText='Choose images'
                                onChange={this.triggerQuestionOptionImage("diverse")}
                                imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                maxFileSize={5242880}
                            />
                        </div>
                    </div>
                );
            }
            default: {
                return null;
            }
        }
    }

    renderQuestionContentArea() {
        const { t, draft_data } = this.props;
        const id = draft_data.question_type ? draft_data.question_type.id : 0;
        if (id === 0) {
            return null;
        } else {
            return (
                <div className="question_content">
                    <label className="question_content__title">{t('Question content')} </label>
                    <div className="question_content__body">
                        <div className="md-form">
                            <input type="text" className="form-control" onChange={this.triggerQuestionContent.bind(this)} />
                        </div>
                    </div>
                    {this.questionTypeOptionsContent()}
                    { id !== 5  && (
                        <>
                            <label className="question_content__title">{t('abbreviation')} </label>
                            <div className="question_content__body">
                                <div className="md-form">
                                    <input type="text" className="form-control" onChange={this.triggerQuestionAbbreviation} />
                                </div>
                            </div>
                            <label className="question_content__title">{t('allowFiles')}</label>
                            <div className="question_content__body">
                                <div className="mt-3 mb-3 text-left">
                                    <div className="form-check form-check-inline">
                                        <input type="radio" className="form-check-input" id="allowFiles_yes" name="allowFiles" onChange={this.onToggleFiles} value="yes" />
                                        <label className="form-check-label" htmlFor="allowFiles_yes">{t('yes')}</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input type="radio" className="form-check-input" id="allowFiles_no" name="allowFiles" onChange={this.onToggleFiles} value="no" defaultChecked/>
                                        <label className="form-check-label" htmlFor="allowFiles_no">{t('no')}</label>
                                    </div>
                                </div>
                            </div>
                            <label className="question_content__title">{`${t('hideFor')}...`}.</label>
                            <div className="question_content__body">
                                <div className="mt-3 mb-3 text-left">
                                    <div className="form-check form-check-inline">
                                        <input onChange={this.onChangeGender} className="form-check-input" type="checkbox" id="male" name="male"/>
                                        <label className="form-check-label" htmlFor="male">{t('male')}</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input onChange={this.onChangeGender} className="form-check-input" type="checkbox" id="female" name="female"/>
                                        <label className="form-check-label" htmlFor="female">{t('female')}</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input onChange={this.onChangeGender} className="form-check-input" type="checkbox" id="diverse" name="diverse"/>
                                        <label className="form-check-label" htmlFor="diverse">{t('diverse')}</label>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            );
        }
    }

    componentWillUnmount() {
        this.props.dropDraftData();
    }

    render() {
        const { t, question_types_list, doctors_list, questionnaire_data, draft_data } = this.props;
        const defaultQuestionTypesOption = {
            value: 0,
            label: t('Choose your question type')
        };
        let questionTypesOptions = question_types_list.data.map(q => {
            return {
                value: q.id,
                label: q.label
            };
        });
        questionTypesOptions.unshift(defaultQuestionTypesOption);
        let defaultQuestionTypesValue = draft_data.question_type ? {value: draft_data.question_type.id, label: draft_data.question_type.label} : defaultQuestionTypesOption;
        const dtableData = draft_data.questions_draft.map(d => {
            const sequence_value = d.sequence ? d.sequence : 1;  
            return {
                id: d.id,
                type: d.label,
                abbreviation: d.abbreviation,
                content: d.content,
                date: new Date(d.createdAt).toDateString(),
                sequence: <input type="number" onChange={(e)=>this.changeQuestionSequence(d.id, e)} className="form-control tiny_form_control_input" value={sequence_value} />,
                delete: <MDBBtn color="dark-green" size="sm" rounded onClick={(question)=>this.deleteQuestion(d)}><MDBIcon icon="archive" className="pr-1"/></MDBBtn>
            };
        });
        const data = {
            columns: [
                {
                    label: t('id'),
                    field: 'id',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: t('type'),
                    field: 'type',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: t('abbreviation'),
                    field: 'abbreviation',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: t('content'),
                    field: 'content',
                    sort: 'asc',
                    width: 270
                },
                {
                    label: t('created-at'),
                    field: 'created_at',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: t('sequence'),
                    field: 'sequence',
                    sort: 'asc',
                },
                {
                    label: t('delete'),
                    field: 'delete'
                }
            ],
            rows: dtableData
        };
        if(this.state.redirect){
            return(
                <Redirect to={"/questionnaires"} />
            )
        } else if (!this.state.isLogin) {
            return <Redirect to="/"/>
        }
        const doctorOptions = doctors_list.data.map(doctor => {
            return {
                value: doctor.name,
                label: doctor.name
            }
        });
        const defaultDoctorOption = questionnaire_data.questionnaire.doctor ? {value: questionnaire_data.questionnaire.doctor.name, label: questionnaire_data.questionnaire.doctor.name, isDisabled: true} : {value: 0, label: t('Choose Doctor')};
        return (
            <div id="profile-v2" className="mb-5">
                <ToastContainer
                    hideProgressBar={true}
                    newestOnTop={true}
                    autoClose={5000}
                />
                <MDBContainer fluid>
                    <MDBRow>
                        <MDBCol lg="4" className="mb-4">
                            <MDBCard narrow>
                                <MDBCardBody className="text-center">
                                    <h3 className="text-muted"><strong>{t('New Question')}</strong></h3>
                                    <Select
                                        value={defaultQuestionTypesValue}
                                        placeholder={t('Choose your question type')}
                                        options={questionTypesOptions}
                                        onChange={this.selectQuestionType}
                                        className={'question_select_custom'}
                                        name='question_type_choose'
                                    />
                                    {this.renderQuestionContentArea()}
                                    <MDBRow center>
                                        <MDBBtn color="info" type="button" rounded size="sm" onClick={()=>this.dropQuestionType()}>Cancel</MDBBtn>
                                        <MDBBtn color="danger" type="submit" rounded size="sm" onClick={()=>this.addQuestion()}>Add</MDBBtn>
                                    </MDBRow>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol lg="8" className="mb-r">
                            <MDBCard narrow>
                                <MDBCardBody>
                                    <h1 className="font-weight-bold">{t('question')}
                                        <MDBBtn color="pink" size="md"  className="right-button" onClick={() => this.save()}>{t('save')}</MDBBtn>
                                    </h1>
                                    <MDBRow>
                                        <MDBCol>
                                            <input type="text" className="form-control" id="questionnaires_name" ref="questionnaires_name_ref" defaultValue={questionnaire_data.questionnaire ? questionnaire_data.questionnaire.name : ''} placeholder={t('name')} />
                                        </MDBCol>
                                        <MDBCol>
                                            <Select
                                                value={defaultDoctorOption}
                                                placeholder={t('Choose Doctor')}
                                                options={doctorOptions}
                                                onChange={this.handleSelectedDoctor}
                                                className={'question_select_custom'}
                                                name='doctor_type_choose'
                                            />
                                        </MDBCol>
                                    </MDBRow>

                                    <MDBDataTable
                                        entriesLabel={t("show-entries")}
                                        searchLabel={t("search")}
                                        infoLabel={[t("showing"), t("to"), t("of"), t("entries")]}
                                        paginationLabel={[t("previous"), t("next")]}
                                        striped
                                        hover
                                        data={data}
                                        paging={true}
                                    />
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
  const {
    question_types_list,
    doctors_list,
    draft_data,
    questionnaire_data
  } = state
  return {
    question_types_list,
    doctors_list,
    draft_data,
    questionnaire_data
  }
};
export default connect(mapStateToProps, {
  loadQuestionTypes,
  injectSelectedQuestionType,
  injectQuestionToDraft,
  injectSelectedDoctorName,
  loadDoctors,
  editQuestionnaire,
  loadQuestionnaire,
  loadQuestionnaireQuestionsEdit,
  injectQuestionToDelete,
  dropDraftData,
  uploadBodyImage
})(withTranslation('common')(QuestionnaireEdit));