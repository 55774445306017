import { createStore, applyMiddleware } from 'redux';
import promiseMiddleware from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';

const configureStore = () => createStore (
  rootReducer,
  applyMiddleware(promiseMiddleware, thunk)
);

export default configureStore;