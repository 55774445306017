import React, {Component} from 'react';
import {
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBIcon,
    MDBView,
    MDBDataTable,
    MDBBtn,
} from 'mdbreact';
import { Line } from "react-chartjs-2";
import { withTranslation } from 'react-i18next';
import {loadState} from "../storage/storage";
import BaseApi from "../api/BaseApi";
import DoctorApi from "../api/DoctorApi";
import AnamnesisApi from "../api/AnamnesisApi";
import PatientApi from "../api/PatientApi";
import QuestionnaireApi from "../api/QuestionnaireApi";
import {Redirect} from 'react-router';
const Link = require("react-router-dom").NavLink;
class DASHBOARD extends Component{
    constructor(props) {
        super(props);
        this.state = {
            date: new Date().toDateString(),
            doctors: [],
            anamnesis: [],
            patients: [],
            questionnaires: [],
            isLogin: true
        }

    }
    componentWillMount() {
        const token1 = loadState('token');
        if (token1) {
            BaseApi.authToken=token1;
        } else {
            this.setState({
                isLogin: false
            })
        }

    }
    componentDidMount() {
            this.initData();
    }

    initData() {
        let doctors = [];
        DoctorApi.getAll().then((res) => {
            console.log('doctors', res.data);
            if (res.data) {
                res.data.map((doctor) => {
                    doctors.push({
                        id: doctor.id,
                        name: doctor.name,
                        created_at: doctor.createdAt,
                        view: <Link to={{pathname:"/doctors/view", doctor:doctor}}><MDBBtn color="pink" size="sm" rounded><MDBIcon icon="low-vision" className="pr-1"/></MDBBtn></Link>
                    })
                })
            }
            this.setState({
                doctors:doctors
            });
        })
        let anamnesis = [];
        AnamnesisApi.getAll().then((res) => {
            if (res.data) {
                res.data.map((anamnes) => {
                    anamnesis.push({
                        id: anamnes.id,
                        patientID: anamnes.patient?anamnes.patient.firstName+" "+anamnes.patient.surName: null,
                        status: anamnes.status,
                        created_at: anamnes.createdAt,
                        view: <Link to={{pathname:"/anamesis/view", anamnesis:anamnes}}><MDBBtn color="pink" size="sm" rounded><MDBIcon icon="low-vision" className="pr-1"/></MDBBtn></Link>,

                    })
                });
                this.setState({
                    anamnesis: anamnesis
                })
            }
        });
        PatientApi.getAll().then((res) => {
            if (res.data) {

                this.setState({
                    patients: res.data
                })
            }
        });
        QuestionnaireApi.getAll().then((res) => {
            if (res.data) {

                this.setState({
                    questionnaires: res.data
                })
            }
        });
    }

    render() {
        const { t } = this.props;
        const doctors = {
            columns: [
                {
                    label: t('id'),
                    field: 'id',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: t('name'),
                    field: 'name',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: t('created-at'),
                    field: 'created_at',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: t('view'),
                    field: 'view'
                }
            ],
            rows: this.state.doctors
        };
        const anamnesis = {
            columns: [
                {
                    label: t('id'),
                    field: 'id',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: t('patientid'),
                    field: 'patientID',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: t('status'),
                    field: 'status',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: t('created-at'),
                    field: 'created_at',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: t('view'),
                    field: 'view'
                },
            ],
            rows: this.state.anamnesis
        };
        const lineChartData= {
            labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            datasets: [
                {
                    label: "Patients",
                    data: [65, 59, 80, 81, 56, 55, 40, 30, 60, 80, 40, 90],
                    backgroundColor: "rgba(105, 0, 132, .2)",
                    borderColor: [
                        'rgba(200, 99, 132, .7)',
                    ],
                    borderWidth: 2
                },
                {
                    label: "Anamnesis",
                    data: [28, 48, 40, 19, 86, 27, 90, 50, 50, 40, 70, 80],
                    backgroundColor: [
                        'rgba(0, 137, 132, .2)',
                    ],
                    borderColor: [
                        'rgba(0, 10, 130, .7)',
                    ],
                    borderWidth: 2
                }
            ]
        }

        if (!this.state.isLogin) {
            return <Redirect to="/"/>
        }

        return (
            <>
                <section className="mb-4">
                    <MDBRow>
                        <MDBCol xl="3" md="6" className="mb-4 mb-r">
                            <MDBCard>
                                <MDBRow className="mt-3">
                                    <MDBCol md="5" size="5" className="text-left pl-4">
                                        <MDBBtn tag="a" floating size="lg" color="primary" className="ml-4" style={{padding:0}}>
                                            <MDBIcon icon="eye" size="2x"/>
                                        </MDBBtn>
                                    </MDBCol>
                                    <MDBCol md="7" col="7" className="text-right pr-5">
                                        <h5 className="ml-4 mt-4 mb-2 font-weight-bold">{this.state.doctors.length}</h5>
                                        <p className="font-small grey-text">{t('doctors')}</p>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="my-3">
                                    <MDBCol md="7" col="7" className="text-left pl-4">
                                        <p className="font-small dark-grey-text font-up ml-4 font-weight-bold">{t('lastmonth')}</p>
                                    </MDBCol>
                                    <MDBCol md="5" col="5" className="text-right pr-5">
                                        <p className="font-small grey-text">0</p>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCard>
                        </MDBCol>

                        <MDBCol xl="3" md="6" className="mb-4 mb-r">
                            <MDBCard>
                                <MDBRow className="mt-3">
                                    <MDBCol md="5" col="5" className="text-left pl-4">
                                        <MDBBtn tag="a" floating size="lg" color="warning" className="ml-4" style={{padding:0}}>
                                            <MDBIcon icon="user" size="2x"/>
                                        </MDBBtn>
                                    </MDBCol>
                                    <MDBCol md="7" col="7" className="text-right pr-5">
                                        <h5 className="ml-4 mt-4 mb-2 font-weight-bold">{this.state.patients.length}</h5>
                                        <p className="font-small grey-text">Patients</p>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="my-3">
                                    <MDBCol md="7" col="7" className="text-left pl-4">
                                        <p className="font-small dark-grey-text font-up ml-4 font-weight-bold">{t('lastmonth')}</p>
                                    </MDBCol>
                                    <MDBCol md="5" col="5" className="text-right pr-5">
                                        <p className="font-small grey-text">0</p>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCard>
                        </MDBCol>

                        <MDBCol xl="3" md="6" className="mb-4 mb-r">
                            <MDBCard>
                                <MDBRow className="mt-3">
                                    <MDBCol md="5" col="5" className="text-left pl-4">
                                        <MDBBtn tag="a" floating size="lg" color="info" className="ml-4" style={{padding:0}}>
                                            <MDBIcon icon="dollar-sign" size="2x"/>
                                        </MDBBtn>
                                    </MDBCol>
                                    <MDBCol md="7" col="7" className="text-right pr-5">
                                        <h5 className="ml-4 mt-4 mb-2 font-weight-bold">{this.state.questionnaires.length} </h5>
                                        <p className="font-small grey-text">{t('questionnaire')}</p>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="my-3">
                                    <MDBCol md="7" col="7" className="text-left pl-4">
                                        <p className="font-small dark-grey-text font-up ml-4 font-weight-bold">{t('lastmonth')}</p>
                                    </MDBCol>
                                    <MDBCol md="5" col="5" className="text-right pr-5">
                                        <p className="font-small grey-text">0</p>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCard>
                        </MDBCol>


                        <MDBCol xl="3" md="6" className="mb-4 mb-r">
                            <MDBCard>
                                <MDBRow className="mt-3">
                                    <MDBCol md="5" col="5" className="text-left pl-4">
                                        <MDBBtn tag="a" floating size="lg" color="danger" className="ml-4" style={{padding:0}}>
                                            <MDBIcon icon="database" size="2x"/>
                                        </MDBBtn>
                                    </MDBCol>
                                    <MDBCol md="7" col="7" className="text-right pr-5">
                                        <h5 className="ml-4 mt-4 mb-2 font-weight-bold">{this.state.anamnesis.length} </h5>
                                        <p className="font-small grey-text">{t('anamnesis')}</p>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="my-3">
                                    <MDBCol md="7" col="7" className="text-left pl-4">
                                        <p className="font-small dark-grey-text font-up ml-4 font-weight-bold">{t('lastmonth')}</p>
                                    </MDBCol>
                                    <MDBCol md="5" col="5" className="text-right pr-5">
                                        <p className="font-small grey-text">0</p>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </section>
                <section className="my-4">

                    <section>
                        <MDBRow className="mb-4">
                            <MDBCol md="12" lg="12" className="mb-4">
                                <MDBCard narrow>
                                    <MDBCardBody>
                                        <Line data={lineChartData} height={100}/>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>

                        </MDBRow>
                    </section>
                    <section>
                        <MDBRow className="mb-4">
                            <MDBCol md="12" lg="6" className="mb-4">
                                <MDBCard narrow  className="z-depth-0">

                                    <MDBView  className="gradient-card   -header blue-gradient narrower py-2 mx-4 mb-4 d-flex justify-content-between align-items-center">

                                        <a href="#!" className="white-text mx-3">{t('recent-doctors')}</a>

                                    </MDBView>
                                    <div className="px-4">
                                        <MDBDataTable
                                            entriesLabel={t("show-entries")}
                                            searchLabel={t("search")}
                                            infoLabel={[t("showing"), t("to"), t("of"), t("entries")]}
                                            paginationLabel={[t("previous"), t("next")]}
                                            hover
                                            responsive
                                            data={doctors}
                                            paging={true}
                                            sortable={true}
                                        />
                                    </div>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol md="12" lg="6" className="mb-4">
                                <MDBCard narrow className="z-depth-0">

                                    <MDBView  className="gradient-card   -header blue-gradient narrower py-2 mx-4 mb-4 d-flex justify-content-between align-items-center">

                                        <a href="#!" className="white-text mx-3">{t('recent-anamnesis')}</a>

                                    </MDBView>
                                    <div className="px-4">
                                        <MDBDataTable
                                            entriesLabel={t("show-entries")}
                                            searchLabel={t("search")}
                                            infoLabel={[t("showing"), t("to"), t("of"), t("entries")]}
                                            paginationLabel={[t("previous"), t("next")]}
                                            striped
                                            hover
                                            responsive
                                            data={anamnesis}
                                            paging={true}
                                        />
                                    </div>
                                </MDBCard>
                            </MDBCol>
                        </MDBRow>


                    </section>

                </section>


            </>
        );
    }

}


export default withTranslation('common')(DASHBOARD);