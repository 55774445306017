export default function reducer(state = {
  data: [],
  selected_doctor_name: '',  
  fetching: true,
  fetched: false,
  error: null
}, action = {}) {
  const { type, payload } = action;
  switch(type) {
    case 'SELECTED_DOCTOR_NAME': {
      return {...state, fetching: false, fetched: true, selected_doctor_name: payload}
    }
    case 'DOCTORS_SUCCESS': {
      return {...state, fetching: false, fetched: true, data: payload}
    }
    case 'DOCTORS_FAILURE': {
      return {...state, fetching: false, error: payload}
    }
    default: {
      return state;
    }
  }
}