import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './assets/css/index.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router-dom';
import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
import common_de from "./assets/translations/de.json";
import common_en from "./assets/translations/en.json";
import {loadState} from "./storage/storage";

import configureStore from './store/configureStore';
const store = configureStore();

let createHistory = require ('history').createBrowserHistory;
const history = createHistory();
i18next.init({
    interpolation: { escapeValue: false },
    lng: loadState("state") ? loadState("state") : "de",
    resources: {
        en: {
            common: common_en
        },
        de: {
            common: common_de
        },
    },
});
ReactDOM.render(
    <I18nextProvider i18n={i18next}>
        <Provider store={store}>
            <Router history={history}>
                <App/>
            </Router>
        </Provider>
    </I18nextProvider>,
document.getElementById('root'));

serviceWorker.unregister();
