export default function reducer(state = {
  data: [],
  fetching: true,
  fetched: false,
  error: null
}, action = {}) {
  const {type, payload} = action
  switch(type) {
    case 'QUESTION_TYPE_SUCCESS': {
      return {...state, fetching: false, fetched: true, data: payload}
    }
    case 'QUESTION_TYPE_FAILURE': {
      return {...state, fetching: false, error: payload}
    }
    default: {
      return state;
    }
  }
}