import { combineReducers } from 'redux';
import question_types_list from './questionTypeReducer';
import doctors_list from './doctorsReducer';
import questionnaire_data from './questionnaireReducer';
import draft_data from './draftReducer';

const rootReducer = combineReducers({
  question_types_list,
  doctors_list,
  draft_data,
  questionnaire_data
});

export default rootReducer;