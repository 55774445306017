import React, {Component} from 'react';
import { 
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBBtn,
  MDBIcon,
    MDBRow, MDBCol,
  MDBDataTable
} from 'mdbreact/dist/mdbreact';
import { withTranslation } from 'react-i18next';
import AnamnesisApi from "../../api/AnamnesisApi";
import {loadState} from "../../storage/storage";
import BaseApi from "../../api/BaseApi";
import {Redirect} from 'react-router';

const Link = require("react-router-dom").NavLink;

class AnamesisList extends Component{
  constructor() {
    super()
    this.state = {
      date: new Date().toDateString(),
      rows: [],
        isLogin: true
    };

  }

  initData() {
        let anamnesis = [];
        AnamnesisApi.getAll().then((res) => {
            if (res.data) {
                res.data.map((anamnes) => {
                    if (anamnes) {
                        anamnesis.push({
                            id: anamnes.id,
                            patientID: anamnes.patient?anamnes.patient.firstName+" "+anamnes.patient.surName: null,
                            status: anamnes.status,
                            created_at: anamnes.createdAt,
                            view: <Link to={{pathname:"/anamesis/view", anamnesis:anamnes}}><MDBBtn color="pink" size="sm" rounded><MDBIcon icon="low-vision" className="pr-1"/></MDBBtn></Link>,

                        })
                    }

                });
                this.setState({
                    rows: anamnesis
                })
            }
        });

    }

    componentWillMount() {
        const token1 = loadState('token');
        if (token1) {
            BaseApi.authToken=token1;
        } else {
            this.setState({
                isLogin: false
            })
        }

    }

  componentDidMount() {
        this.initData();
  }

  render() {
    const {t}=this.props;
    const data = {
          columns: [
              {
                  label: t('id'),
                  field: 'id',
                  sort: 'asc',
                  width: 150
              },
              {
                  label: t('patientid'),
                  field: 'patientID',
                  sort: 'asc',
                  width: 150
              },
              {
                  label: t('status'),
                  field: 'status',
                  sort: 'asc',
                  width: 200
              },
              {
                  label: t('created-at'),
                  field: 'created_at',
                  sort: 'asc',
                  width: 100
              },
              {
                  label: t('view'),
                  field: 'view'
              },
          ],
          rows: this.state.rows
      };

      if (!this.state.isLogin) {
          return <Redirect to="/"/>
      }

    return (
        <MDBContainer className="mt-3">
            <MDBRow className="py-3">
                <MDBCol md="12">
                    <MDBCard>
                        <MDBCardBody>
                            <h1 className="font-weight-bold">{t('anamnesis')}</h1>
                            <MDBDataTable
                                entriesLabel={t("show-entries")}
                                searchLabel={t("search")}
                                infoLabel={[t("showing"), t("to"), t("of"), t("entries")]}
                                paginationLabel={[t("previous"), t("next")]}
                                striped
                                hover
                                data={data}
                                paging={true}
                            />
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>

            </MDBRow>

        </MDBContainer>
    );
  }

}


export default withTranslation('common')(AnamesisList);
